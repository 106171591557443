import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../config/config";
import ReactSelect, { components } from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { getAdminDetails } from "../../Redux/slices/admin.slice";
import { setAllContracts, getFilters } from "../../Redux/slices/contract.slice";
import { Offcanvas, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Activite from "./Activite";
import Chat from "./Chat";
import Notes from "./Notes";
import { format, addMinutes } from "date-fns";
import { setPopup } from "../../Redux/slices/actions.slice";
import { useParams } from "react-router-dom";

const UpdateContracts = ({
  isVisible,
  onHide,
  data,
  IsForComments,
  GetAllContrats,
  modalRubrique
}) => {
  // DATA RECUPERER POUR LE CONTRAT SELECTIONNE

  const { desk, idcontract, mode } = useParams();

  useEffect(() => {
    if (mode) {
      setSelectedTitle(mode);
    }
  }, [mode]);
  useEffect(() => {
      if(modalRubrique === "Notes"){
      setSelectedTitle("Notes");
    }
    if(modalRubrique !== "Notes"){
      setSelectedTitle("Contrat");
    }
  }, [ modalRubrique]);

  const pseudoAdmin = useSelector(getAdminDetails);

  const [prenomC, setPrenomC] = useState(data?.cust_fname);
  const [nomC, setNomC] = useState(data?.cust_lname);
  const [brandC, setBrandC] = useState(data?.brand);
  const [amountC, setAmountC] = useState(data?.amount);
  const [pspC, setPspC] = useState(data?.psp);
  const [deskC, setDeskC] = useState(data?.desk);
  const [neoBankC, setNeoBankC] = useState(data?.neobank);
  const [customerbankC, setCustomerbankC] = useState(data?.customerbank);
  const [sellerC, setSellerC] = useState(data?.seller_id);
  const [supportC, setSupportC] = useState(data?.agt_supp_id);
  const [amountEncaisseC, setAmountEncaisseC] = useState(data?.encash_usd);
  const [isConversionC, setIsConversionC] = useState(data?.conversion);

  // dates et heures

  const [date_virC, setDate_virC] = useState(
    data?.date_vir ? new Date(data?.date_vir) : null
  );
  const [dateEncaissementC, setDateEncaissementC] = useState(
    data?.date_encaisse ? new Date(data?.date_encaisse) : null
  );
  const [next_callC, setNext_callC] = useState(
    data.next_call ? new Date(data?.next_call) : null
  );
  const [duDateC, setDuDateC] = useState(
    data?.paymentdudate ? new Date(data?.paymentdudate) : null
  );

  const [created_atC, setCreated_atC] = useState(
    data?.created_at ? new Date(data?.created_at) : null
  );

  const [hoursCSel, setHoursCSel] = useState(data?.hours);

  // status

  const [argent_dispoC, setArgent_dispoC] = useState(data?.argent_dispo);
  const [preuveC, setPreuveC] = useState(data?.preuve);
  const [encaisseC, setEncaisseC] = useState(data?.encaisse);
  const [is_reportC, setIs_reportC] = useState(data?.is_report);
  const [is_canceledC, setIs_canceledC] = useState(data?.is_canceled);

  // DATA POUR AFFICHAGE DES SELECTS

  const [psp, setPsp] = useState([]);
  const [neoBank, setNeoBank] = useState([]);
  const [customerBank, setCustomerBank] = useState([]);
  const [listSellAndTlead, setListSellAndTlead] = useState([]);
  const [listSupport, setListSupport] = useState([]);
  // autres

  const [error, setError] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState("Contrat");
  const [errorVirgule, setErrorVirgule] = useState(false);
  const [errorVirguleEncai, setErrorVirguleEncai] = useState(false);

  const [loaderButton, setLoaderButton] = useState(false);

  const AdminDetails = useSelector(getAdminDetails);
  const desksToAdmin = AdminDetails?.desk;
  const brandToAdmin = AdminDetails?.brand;
  const profilToAdmin = AdminDetails?.profil;
  const filters = useSelector(getFilters);

  const [isDoubleLine, setIsDoubleLine] = useState(false);

  /////////// gestion de erreur avec des states

  const [errorDateEncaissementC, setErrorDateEncaissementC] = useState("");
  const [errorAmountEncaissementC, setErrorAmountEncaissementC] = useState("");
  const [errorCreatedAtC, setErrorCreatedAtC] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    setPrenomC(data?.cust_fname);
    setNomC(data?.cust_lname);
    setBrandC(data?.brand);
    setAmountC(data?.amount);
    setIsConversionC(data?.conversion);
    // setPaymentdudateC(data?.paymentdudate);
    setArgent_dispoC(data?.argent_dispo);
    setPreuveC(data?.preuve);
    setEncaisseC(data?.encaisse);
    setDate_virC(data?.date_vir ? new Date(data?.date_vir) : null);
    setNext_callC(data.next_call ? new Date(data?.next_call) : null);
    setDuDateC(data?.paymentdudate ? new Date(data?.paymentdudate) : null);
    setCreated_atC(data?.created_at ? new Date(data?.created_at) : null);
    // setHoursC(data?.hours);
    setIs_reportC(data?.is_report);
    setIs_canceledC(data?.is_canceled);
    setPspC(data?.psp);
    setNeoBankC(data?.neobank);
    setCustomerbankC(data?.customerbank);
    setSellerC(data?.seller_id);
    setSupportC(data?.agt_supp_id);
    setDeskC(data?.desk);
    setHoursCSel(data?.hours);
    setAmountEncaisseC(data?.encash_usd);
    setDateEncaissementC( data?.date_encaisse ? new Date(data?.date_encaisse) : null );
    setErrorDateEncaissementC("");
    setErrorCreatedAtC("");
    setLoaderButton(false);
  }, [data]);

  let Header = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("tokenadmin")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    if (isVisible) {
      FetchFiltersGet("/psp/read_all", setPsp);
      FetchFiltersGet("/neobanks/read_all", setNeoBank);
      FetchFiltersPost("/users/all_agt_supp_and_mng_supp", setListSupport);
      FetchFiltersGet("/customerbanks/read_all", setCustomerBank);
      FetchFiltersPost("/users/all_sell_and_tlead", setListSellAndTlead, {
        desk: desksToAdmin,
      });
    }
    // eslint-disable-next-line
  }, [isVisible]);
  const FetchFiltersGet = (url, fonction) => {
    axios
      .get(`${config.apiAdmin}/sadmin${url}`, Header)
      .then((response) => {
        fonction(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  const FetchFiltersPost = (url, fonction, obj) => {
    axios
      .post(`${config.apiAdmin}/sadmin${url}`, obj, Header)
      .then((response) => {
        fonction(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const optionsSellandtlead = listSellAndTlead?.map((item) => ({
    value: item?.idusers,
    label: item?.pseudo,
    profil: item?.profil,
  }));

  const optionsDesk = desksToAdmin?.map((item) => ({
    value: item,
    label: item,
  }));

  const optionsSupport = listSupport?.map((support) => ({
    value: support.idusers,
    label: support.pseudo,
    profil:
      support.profil === "agt_supp"
        ? "Support"
        : support.profil === "mng_supp"
        ? "MgSupport"
        : support.profil === "boss_supp"
        ? "BossSupport"
        : support.profil,
  }));

  const defaultValueSupport = {
    value: `${data?.agt_supp_id}`,
    label: `${data?.pseudo_agt_supp}`,
  };

  const defaultValueSell = {
    value: `${data?.seller_id}`,
    label: `${data?.pseudo_seller}`,
  };

  const defaultValueDesk = {
    value: `${data?.desk}`,
    label: `${data?.desk}`,
  };

  const defaultValueBrand = {
    value: brandC,
    label: brandC,
  };

  const optionsBrands = brandToAdmin?.map((item) => ({
    value: item,
    label: item,
  }));

  const defaultValuePsp = {
    value: pspC,
    label: pspC,
  };

  const pspSelect = psp?.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  const defaultValueNeo = {
    value: neoBankC,
    label: neoBankC,
  };

  const neoSelect = neoBank?.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  const defaultValueBank = {
    value: customerbankC,
    label: customerbankC,
  };

  const BankSelect = customerBank?.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  const SendPopup = (popup) => {
    dispatch(setPopup(popup));
  };

  const UpdateContract = (data) => {
    setLoaderButton(true);
    if (encaisseC === 1) {
      if (!amountEncaisseC || amountEncaisseC === 0) {
        // setErrorEncaisse(true);
        setErrorAmountEncaissementC("merci de renseigenr un montant");
        return;
      }
    }

    // datePicker ne prend que de new date, qui est une fonction, alors comme on besoin de la renvoyer en string,
    // on la retransforme a la fin, juste avant de l'envoyer
    let next_callCStr = next_callC?.toISOString();
    next_callCStr = next_callCStr?.slice(0, 10);

    // datePicker ne prend que de new date, qui est une fonction, alors comme on besoin de la renvoyer en string,
    // on la retransforme a la fin, juste avant de l'envoyer
    let date_virCStr = date_virC?.toISOString();
    date_virCStr = date_virCStr?.slice(0, 10);

    let date_encaissementStr = dateEncaissementC?.toISOString();
    date_encaissementStr = date_encaissementStr?.slice(0, 10);

    let created_atStr = created_atC?.toISOString();
    if (created_atStr) {
      let date = new Date(created_atStr);
      // date.setDate(date.getDate() - 1); // Soustraire un jour
      created_atStr = date.toISOString().slice(0, 10); // Convertir en chaîne de caractères au format YYYY-MM-DD
    }

    let date_duDateStr = duDateC?.toISOString();
    date_duDateStr = date_duDateStr?.slice(0, 10);

    let obj = {
      pseudo: AdminDetails?.pseudo,
      oldState: data,
      newState: {
        idcontracts: data?.idcontracts,
        created_at: created_atStr,
        brand: brandC,
        desk: deskC,
        amount: amountC,
        conversion: isConversionC,
        end_contract: data?.end_contract?.slice(0, 10),
        psp: pspC,
        neobank: neoBankC,
        customerbank: customerbankC,
        customer_id: data?.customer_id,
        seller_id: sellerC,
        agt_supp_id: supportC,
        paymentdudate: date_duDateStr,
        argent_dispo: argent_dispoC,
        preuve: preuveC,
        date_vir: date_virCStr,
        encaisse: encaisseC,
        next_call: next_callCStr,
        hours: hoursCSel,
        date_encaisse: date_encaissementStr,
        encash_usd: amountEncaisseC,
        is_report: is_reportC,
        is_canceled: is_canceledC,
        filters: filters,
        customer: {
          firstname: prenomC,
          lastname: nomC,
          crm_id: data?.customer_id,
        },
      },
    };
    axios
      .patch(`${config.apiAdmin}/sadmin/contracts/updateAll`, obj, Header)
      .then((response) => {
        dispatch(setAllContracts(response.data.data.results));
        SendPopup({ message: "confirm", info: "updateAll.", isActive: true });
        onHide();
        setLoaderButton(false);
      })
      .catch((error) => {
        setError(true);
        setLoaderButton(false);
        SendPopup({ message: "error", info: "updateAll.", isActive: true });
        // reject(error); // Reject the promise on error
      });
    setLoaderButton(false);
  };

  const handleTitleClick = (title) => {
    setLoaderButton(false);
    setSelectedTitle(title);
    setErrorAmountEncaissementC("");
    setErrorDateEncaissementC("");
    setErrorCreatedAtC("");
  };

  const handleHours = (date) => {
    // const formattedDate = format(date, "HH:mm:ss");
    // console.log(date);
    // setHoursCSel(formattedDate);
    setHoursCSel(date);
  };

  const handleNextCall = (date) => {
    if (date === null) {
      setNext_callC(null);
    } else {
      const selectedDate = new Date(date);
      const timezoneOffset = new Date().getTimezoneOffset();
      const dateInUTC = addMinutes(selectedDate, -timezoneOffset);
      // console.log(dateInUTC);
      setNext_callC(dateInUTC);
    }
  };

  const handleDate_vir = (date) => {
    if (date === null) {
      setDate_virC(null);
      return;
    }
    const selectedDate = new Date(date);
    const timezoneOffset = new Date().getTimezoneOffset();
    const dateInUTC = addMinutes(selectedDate, -timezoneOffset);
    setDate_virC(dateInUTC);
  };

  const handleDateEncaissement = (date) => {
    if (date === null) {
      setDateEncaissementC(null);
      return;
    }
    const selectedDate = new Date(date);
    const timezoneOffset = new Date().getTimezoneOffset();
    const dateInUTC = addMinutes(selectedDate, -timezoneOffset);

    // Obtenir l'année courante
    const currentYear = new Date().getFullYear();
    // Définir les bornes de l'année acceptable (année -1 et année +1)
    const minYear = currentYear - 1;
    const maxYear = currentYear + 1;

    // Vérifier si la date sélectionnée est dans l'intervalle de l'année
    const selectedYear = dateInUTC.getFullYear();
    if (selectedYear < minYear || selectedYear > maxYear) {
      setErrorDateEncaissementC("La date sélectionnée doit être cohérente");
      setLoaderButton(true); // Si hors intervalle, on met une erreur
    } else {
      setErrorDateEncaissementC(false); // Pas d'erreur, on continue
      setDateEncaissementC(dateInUTC);
      setLoaderButton(false);
    }
  };

  const handleCreatedAtC = (date) => {
    if (date === null) {
      setCreated_atC(null); // ou "" si vous préférez une chaîne vide
      return;
    }
    const selectedDate = new Date(date);
    const timezoneOffset = new Date().getTimezoneOffset();
    const dateInUTC = addMinutes(selectedDate, -timezoneOffset);

    // Obtenir l'année courante
    const currentYear = new Date().getFullYear();
    // Définir les bornes de l'année acceptable (année -1 et année +1)
    const minYear = currentYear - 1;
    const maxYear = currentYear + 1;

    // Vérifier si la date sélectionnée est dans l'intervalle de l'année
    const selectedYear = dateInUTC.getFullYear();
    if (selectedYear < minYear || selectedYear > maxYear) {
      setErrorCreatedAtC("La date sélectionnée doit être cohérente");
      setLoaderButton(true); // Si hors intervalle, on met une erreur
    } else {
      setErrorCreatedAtC(false); // Pas d'erreur, on continue
      setCreated_atC(dateInUTC);
      setLoaderButton(false);
    }
  };

  const handleDuDateC = (date) => {
    if (date === null) {
      setDuDateC(null);
      return;
    }
    const selectedDate = new Date(date);
    const timezoneOffset = new Date().getTimezoneOffset();
    const dateInUTC = addMinutes(selectedDate, -timezoneOffset);
    // const formattedDate = format(dateInUTC, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
    setDuDateC(dateInUTC);
  };

  const DoubleLine = () => {
    setLoaderButton(true);
    // datePicker ne prend que de new date, qui est une fonction, alors comme on besoin de la renvoyer en string,
    // on la retransforme a la fin, juste avant de l'envoyer
    let next_callCStr = next_callC?.toISOString();
    next_callCStr = next_callCStr?.slice(0, 10);

    // datePicker ne prend que de new date, qui est une fonction, alors comme on besoin de la renvoyer en string,
    // on la retransforme a la fin, juste avant de l'envoyer
    let date_virCStr = date_virC?.toISOString();
    date_virCStr = date_virCStr?.slice(0, 10);

    let date_encaissementStr = dateEncaissementC?.toISOString();
    date_encaissementStr = date_encaissementStr?.slice(0, 10);

    let obj = {
      idcontracts: data?.idcontracts,
      brand: brandC,
      desk: deskC,
      amount: 1,
      // conversion: isConversionC,
      end_contract: data?.end_contract?.slice(0, 10),
      psp: pspC,
      neobank: neoBankC,
      customerbank: customerbankC,
      customer_id: data?.customer_id,
      seller_id: sellerC,
      agt_supp_id: supportC,
      mng_supp_id: data?.mng_supp_id,
      tlead_id: data?.tlead_id,
      paymentdudate: data?.paymentdudate?.slice(0, 10),
      argent_dispo: argent_dispoC,
      preuve: preuveC,
      date_vir: date_virCStr,
      encaisse: encaisseC,
      next_call: next_callCStr,
      hours: hoursCSel,
      date_encaisse: date_encaissementStr,
      encash_usd: amountEncaisseC,
      is_report: is_reportC,
      is_canceled: is_canceledC,
      created_at: data?.created_at?.slice(0, 10),
    };
    // resetStates();
    axios
      .put(`${config.apiAdmin}/sadmin/contracts/doubleLine`, obj, Header)
      .then((response) => {
        // console.log(response.data)
        setLoaderButton(false);
        // dispatch(setAllContracts(response.data.data.results));
        SendPopup({ message: "confirm", info: "double line.", isActive: true });
        onHide();
        // resetStates();
        GetAllContrats({ data: true });
        // GetAllContrats();
        // si je lui a faire GetAllContrats sans data mais du coup garder les filtres pour a la place de read_table_arr mettre sort_and_search
      })
      .catch((error) => {
        setLoaderButton(false);
        setError(true);
        SendPopup({ message: "error", info: "double line.", isActive: true });
        // console.error("There was an error!", error);
      });
  };

  const CustomDrop = (props) => {
    return (
      <components.Option {...props}>
        <div className="d-flex justify-content-between align-items-center text-black">
          <span>{props.data.label}</span>
          <span
            style={{ color: "white" }}
            className={`badge badge-${props.data.profil}`}
          >
            {props.data.profil}
          </span>
        </div>
      </components.Option>
    );
  };

  return (
    <Offcanvas
      show={isVisible}
      onHide={() => {
        onHide();
      }}
      placement="end"
      style={{ width: "600px" }}
      className="bg-light"
    >
      <Offcanvas.Header className="pb-0">
        <div className="d-flex justify-content-around col-12 title-Modif border-bottom text-black fw-bold">
          <div
            style={{
              borderBottom:
                selectedTitle === "Contrat" ? "3px solid #25e87f" : "none",
              paddingBottom: selectedTitle === "Contrat" ? "10px" : "none",
            }}
            onClick={() => handleTitleClick("Contrat")}
            className="col-3 text-center pb-2"
          >
            Contrat
          </div>
          <div
            style={{
              borderBottom:
                selectedTitle === "Notes" ? "3px solid #E3B637" : "none",
            }}
            onClick={() => handleTitleClick("Notes")}
            className="col-3 text-center pb-2"
          >
            Notes
          </div>
          <div
            style={{
              borderBottom:
                selectedTitle === "Chat" ? "3px solid #25e87f" : "none",
            }}
            onClick={() => handleTitleClick("Chat")}
            className="col-3 text-center pb-2"
          >
            Chat
          </div>
          <div
            style={{
              borderBottom:
                selectedTitle === "Activite" ? "3px solid #25e87f" : "none",
            }}
            onClick={() => handleTitleClick("Activite")}
            className="col-3 text-center"
          >
            Activite
          </div>
        </div>
      </Offcanvas.Header>
      {selectedTitle === "Contrat" && (
        <Offcanvas.Body className="bg-light">
          <div className="text-black">
            {(profilToAdmin === "boss_supp" ||
              profilToAdmin === "admin" ||
              profilToAdmin === "mng_supp") && (
              <div className="d-flex justify-content-between align-items-center mb-1">
                <div className="mt-2 d-flex justify-content-between align-items-center">
                  <div>Activer Double Ligne</div>
                  <Form.Check
                    type="switch"
                    className="custom-switch-drop ms-3 pb-2"
                    checked={isDoubleLine}
                    onChange={(event) => setIsDoubleLine(event.target.checked)}
                    style={{ border: "none", transform: "scale(1.5)" }}
                  />
                </div>
                {isDoubleLine && (
                  <div
                    className="btn btn-green fw-bold text-black py-1"
                    onClick={() => DoubleLine()}
                  >
                    Confirmer
                  </div>
                )}
              </div>
            )}

            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="d-flex">
                <div
                  style={{ textDecoration: "underline" }}
                  className="fw-bold"
                >
                  Informations Client
                </div>
                <div className="ms-2">Crm_Id : {data?.crm_id}</div>
              </div>
              {(profilToAdmin === "manager" || profilToAdmin === "ass_mng") && (
                <div className="d-flex">
                  <div className="me-3">Conversion</div>
                  <div>
                    <Form.Check
                      type="switch"
                      className="custom-switch-drop pb-2"
                      required
                      style={{ transform: "scale(1.5)" }}
                      checked={isConversionC}
                      onChange={(e) =>
                        setIsConversionC(e.target.checked ? 1 : 0)
                      }
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="grid-container">
              {(profilToAdmin === "boss_supp" ||
                profilToAdmin === "mng_supp") && (
                <div className="grid-item p-0 ms-1">
                  <div className="mx-2 mb-1">Sélection support</div>
                  <ReactSelect
                    options={optionsSupport}
                    className="basic-single"
                    classNamePrefix="select"
                    name="alias"
                    placeholder=""
                    defaultValue={defaultValueSupport}
                    onChange={(selectedOption) =>
                      setSupportC(selectedOption.value)
                    }
                    components={{ Option: CustomDrop }}
                  />
                </div>
              )}

              <div className="grid-item p-0 me-1">
                <div className="mx-2 mb-1">Sélection agent</div>
                <ReactSelect
                  options={optionsSellandtlead}
                  className="basic-single"
                  classNamePrefix="select"
                  name="alias"
                  placeholder="Selectionnez votre seller"
                  defaultValue={defaultValueSell}
                  onChange={(selectedOption) =>
                    setSellerC(selectedOption ? selectedOption.value : null)
                  }
                  components={{ Option: CustomDrop }}
                />
              </div>
              <div className="grid-item p-0 me-1">
                <div className="mx-2 mb-1">Prénom</div>
                <input
                  type="text"
                  name="firstName"
                  className="form-control"
                  required
                  defaultValue={prenomC}
                  onChange={(e) => setPrenomC(e.target.value.trimStart())}
                />
              </div>
              <div className="grid-item p-0 me-1">
                <div className="mx-2 mb-1">Nom</div>
                <input
                  type="text"
                  name="lastName"
                  className="form-control"
                  placeholder="Nom du Client"
                  required
                  defaultValue={nomC}
                  onChange={(e) => setNomC(e.target.value.trimStart())}
                />
              </div>
              <div className="grid-item p-0 me-1">
                <div className="mx-2 mb-1">Brand</div>
                <ReactSelect
                  options={optionsBrands}
                  className="basic-single"
                  classNamePrefix="select"
                  name="brand"
                  placeholder=""
                  defaultValue={defaultValueBrand}
                  onChange={(selectedOption) =>
                    setBrandC(selectedOption ? selectedOption.value : null)
                  }
                  isClearable={true}
                />
              </div>
              <div className="grid-item p-0 me-1">
                <div className="mx-2 mb-1">Montant €</div>
                <input
                  type="number"
                  className="p-2 rounded border w-100"
                  required
                  defaultValue={amountC}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.includes(",") || value.includes(".")) {
                      setErrorVirgule(true);
                    } else {
                      setErrorVirgule(false);
                    }
                    setAmountC(value);
                  }}
                />{" "}
                {errorVirgule && (
                  <div className="text-danger fs-16">uniquement un nombre</div>
                )}
              </div>
              <div className="grid-item p-0 me-1">
                <div className="mx-2 mb-1">Date d'échéance</div>
                <DatePicker
                  className="p-2 rounded border"
                  selected={duDateC ? new Date(duDateC) : null}
                  onChange={(date) => handleDuDateC(date)}
                  dateFormat="dd-MM-yyyy"
                />
              </div>
              <div className="grid-item p-0 me-1">
                <div className="mx-2 mb-1">Desk</div>
                <ReactSelect
                  options={optionsDesk}
                  className="basic-single"
                  classNamePrefix="select"
                  name="alias"
                  placeholder="Selectionnez votre desk"
                  defaultValue={defaultValueDesk}
                  onChange={(selectedOption) =>
                    setDeskC(selectedOption ? selectedOption.value : null)
                  }
                  components={{ Option: CustomDrop }}
                />
              </div>
              {profilToAdmin === "ass_mng" && (
                <div className="grid-item p-0 me-1">
                  <div className="mx-2 mb-1">Date de création</div>
                  <div className="d-flex flex-column">
                    <DatePicker
                      className={`p-2 rounded border ms-2 ${
                        errorCreatedAtC ? "border-danger" : ""
                      }`}
                      selected={created_atC ? new Date(created_atC) : null}
                      onChange={(date) => handleCreatedAtC(date)}
                      dateFormat="dd-MM-yyyy"
                    />
                    <small className="text-danger">{errorCreatedAtC}</small>
                  </div>
                </div>
              )}
            </div>
            <div className="m-2">
              <div className="border my-2"></div>
              <div className="text-black">
                <div
                  style={{ textDecoration: "underline" }}
                  className="mb-3 fw-bold mt-1"
                >
                  Gestion support
                </div>
                <div className="grid-container">
                  <div className="grid-item p-0 me-1">
                    <div className="mx-2 mb-1">PSP</div>
                    <ReactSelect
                      options={pspSelect}
                      className="basic-single"
                      classNamePrefix="select"
                      name="psp"
                      placeholder=""
                      defaultValue={defaultValuePsp}
                      onChange={(selectedOption) =>
                        setPspC(selectedOption ? selectedOption.value : null)
                      }
                      isClearable={true}
                    />
                  </div>
                  <div className="grid-item p-0 me-1">
                    <div className="mx-2 mb-1">Plateforme</div>
                    <ReactSelect
                      options={neoSelect}
                      className="basic-single"
                      classNamePrefix="select"
                      name="NeoBank"
                      placeholder=""
                      defaultValue={defaultValueNeo}
                      onChange={(selectedOption) =>
                        setNeoBankC(
                          selectedOption ? selectedOption.value : null
                        )
                      }
                      isClearable={true}
                    />
                  </div>
                  <div className="grid-item p-0 me-1">
                    <div className="mx-2 mb-1">Argent disponible</div>
                    <div className="d-flex m-2 align-items-center">
                      <div className="me-3 mt-2">NON / OUI</div>
                      <Form.Check
                        type="switch"
                        className="custom-switch-drop"
                        required
                        style={{ transform: "scale(1.5)" }}
                        checked={argent_dispoC}
                        onChange={(e) =>
                          setArgent_dispoC(e.target.checked ? 1 : 0)
                        }
                      />
                    </div>
                  </div>
                  <div className="grid-item p-0 me-1">
                    <div className="mx-2 mb-1">Banque client</div>
                    <ReactSelect
                      options={BankSelect}
                      className="basic-single"
                      classNamePrefix="select"
                      name="Banque"
                      placeholder=""
                      defaultValue={defaultValueBank}
                      onChange={(selectedOption) =>
                        setCustomerbankC(
                          selectedOption ? selectedOption.value : null
                        )
                      }
                      isClearable={true}
                    />
                  </div>
                  <div className="grid-item p-0 me-1">
                    <div className="mx-2 mb-1">Preuve de virement</div>
                    <div className="d-flex m-2">
                      <div className="me-3 mt-2">NON / OUI</div>
                      <Form.Check
                        type="switch"
                        className="custom-switch-drop"
                        required
                        style={{ transform: "scale(1.5)" }}
                        checked={preuveC}
                        onChange={(e) => setPreuveC(e.target.checked ? 1 : 0)}
                      />
                    </div>
                  </div>
                  <div className="grid-item p-0 me-1">
                    <div className="mx-2 mb-1">Date du virement</div>
                    <DatePicker
                      className="p-2 rounded border"
                      onChange={(date) => {
                        handleDate_vir(date);
                      }}
                      selected={date_virC}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="m-2">
              <div className="border my-2"></div>
              <div className="text-black">
                <div
                  style={{ textDecoration: "underline" }}
                  className="mb-3 fw-bold mt-1"
                >
                  Rappel
                </div>
                <div className="grid-container">
                  <div className="grid-item p-0 me-1">
                    <div className="mx-2 mb-1">Date de rappel</div>
                    <DatePicker
                      className="p-2 rounded border ms-2"
                      onChange={(date) => {
                        handleNextCall(date);
                      }}
                      selected={next_callC}
                      dateFormat="dd-MM-yyyy"
                    />
                  </div>
                  <div className="grid-item p-0 me-1">
                    {/* <div className="mx-2 mb-1">Heure de rappel</div>
                    <DatePicker
                      onChange={(date) => {
                        // console.log(date)
                        // handleHours(date);
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      value={hoursCSel}
                      timeCaption="Heure"
                      dateFormat="HH:mm:ss"
                      className="p-2 rounded border ms-2"
                      isClearable={true}
                    /> */}
                    <div className="grid-item p-0 me-1">
                      <div className="mx-2 mb-1">Heure de rappel</div>
                      <input
                        type="time"
                        name="time"
                        className="form-control"
                        value={hoursCSel === "00:00:00" ? "" : hoursCSel}
                        onChange={
                          (e) =>
                            // console.log(e.target.value)
                            handleHours(e.target.value)
                          // setHoursClient(e.target.value)
                        }
                      />
                    </div>
                    <button
                      // onClick={() => setHoursCSel(null)}
                      onClick={() => setHoursCSel("")}
                      className="btn text-center ms-4 py-0"
                    >
                      Clear Date
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="border m-2"></div>
            <div className="m-2 text-black">
              <div
                style={{ textDecoration: "underline" }}
                className="mb-3 fw-bold mt-1"
              >
                Choix
              </div>
            </div>
            <div className="m-2 d-flex text-black justify-content-around">
              <div>
                <div>Encaissé</div>
                <div className="d-flex m-2">
                  <div className="me-3 mt-2">NON / OUI</div>
                  <Form.Check
                    type="switch"
                    className="custom-switch-drop"
                    required
                    style={{ transform: "scale(1.5)" }}
                    checked={encaisseC}
                    onChange={(e) => {
                      setEncaisseC(e.target.checked ? 1 : 0);
                      if (!e.target.checked) {
                        setDateEncaissementC(null);
                        setAmountEncaisseC(0);
                      }
                    }}
                  />
                </div>
              </div>
              <div>
                <div>Reporté</div>
                <div className="d-flex m-2">
                  <div className="me-3 mt-2">NON / OUI</div>
                  <Form.Check
                    type="switch"
                    className="custom-switch-drop"
                    required
                    style={{ transform: "scale(1.5)" }}
                    checked={is_reportC}
                    onChange={(e) => setIs_reportC(e.target.checked ? 1 : 0)}
                  />
                </div>
              </div>
              <div>
                <div>Annulé</div>
                <div className="d-flex m-2">
                  <div className="me-3 mt-2">NON / OUI</div>
                  <Form.Check
                    type="switch"
                    className="custom-switch-drop"
                    required
                    style={{ transform: "scale(1.5)" }}
                    checked={is_canceledC}
                    onChange={(e) => setIs_canceledC(e.target.checked ? 1 : 0)}
                  />
                </div>
              </div>
            </div>
            {Number(encaisseC) !== 0 && (
              <>
                <div className="border m-2"></div>
                <div className="m-2 text-black">
                  <div
                    style={{ textDecoration: "underline" }}
                    className="mb-3 fw-bold mt-1"
                  >
                    Encaissement
                  </div>
                </div>
                <div className="grid-container text-black mb-2">
                  <div className="grid-item p-0 me-1">
                    <div className="mx-3 mb-1">Date encaissement</div>
                    <div className="d-flex flex-column">
                      <DatePicker
                        // className="p-2 rounded border ms-2"
                        className={`p-2 rounded border ms-2 ${
                          errorDateEncaissementC ? "border-danger" : ""
                        }`}
                        onChange={(date) => {
                          handleDateEncaissement(date);
                        }}
                        selected={dateEncaissementC}
                        dateFormat="dd-MM-yyyy"
                      />
                      <small className="text-danger">
                        {errorDateEncaissementC}
                      </small>
                    </div>
                  </div>
                  <div className="grid-item p-0 me-1">
                    <div className="mx-1 mb-1">Montant encaissé $</div>
                    <input
                      type="number"
                      className={`form-control w-50 py-0 ${
                        errorAmountEncaissementC || errorVirguleEncai
                          ? "border-danger"
                          : ""
                      }`}
                      placeholder="En Dollars"
                      required
                      defaultValue={
                        amountEncaisseC === 0 ? null : amountEncaisseC
                      }
                      onChange={(e) => {
                        const value = e.target.value;
                        if (!value || value === 0) {
                          setErrorAmountEncaissementC(
                            "merci de renseigenr un montant"
                          );
                          setLoaderButton(true);
                        } else {
                          setErrorAmountEncaissementC("");
                          setLoaderButton(false);
                        }
                        if (value.includes(",") || value.includes(".")) {
                          setErrorVirguleEncai(true);
                        } else {
                          setErrorVirguleEncai(false);
                        }
                        setAmountEncaisseC(value);
                      }}
                    />
                    <small className="text-danger">
                      {errorAmountEncaissementC}
                    </small>
                    {errorVirguleEncai && (
                      <small className="text-danger">
                        uniquement un nombre entier
                      </small>
                    )}
                  </div>
                </div>
              </>
            )}
            <div className=" p-1 d-flex">
              <button
                disabled={loaderButton}
                className={`border btn btn-green ${
                  profilToAdmin === "boss_supp" ||
                  profilToAdmin === "mng_supp" ||
                  pseudoAdmin.pseudo === "antoine"
                    ? "col-10"
                    : "col-12"
                } text-black h-50 me-2`}
                onClick={() => UpdateContract(data)}
              >
                Valider
              </button>

              {(profilToAdmin === "boss_supp" || profilToAdmin === "mng_supp" ||
                pseudoAdmin.pseudo === "antoine" || pseudoAdmin.pseudo === "julie" ) && (
                <button
                  onClick={() => {
                    let confirResponse = window.confirm("Etes vous sur ?");
                    if (confirResponse) {
                      axios
                        .patch(
                          `${config.apiAdmin}/sadmin/contracts/desactivate`,
                          {
                            idcontracts: data?.idcontracts,
                          },
                          Header
                        )
                        .then((response) => {
                          SendPopup({
                            message: "confirm",
                            info: "DeleteContract.",
                            isActive: true,
                          });
                          // dispatch(setAllContracts(response.data.data.results));
                          GetAllContrats({ data: true });
                          onHide();
                          // resetStates();
                        })
                        .catch((error) => {
                          setError(true);
                          SendPopup({
                            message: "error",
                            info: "DeleteContract.",
                            isActive: true,
                          });
                        });
                    }
                  }}
                  className="btn btn-danger"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="black"
                    className="bi bi-trash3-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                  </svg>
                </button>
              )}
            </div>
            {error && (
              <div className="text-red fs-16 m-2">Une erreur est survenue</div>
            )}
          </div>
        </Offcanvas.Body>
      )}
      {selectedTitle === "Activite" && <Activite contrat={data} />}
      {selectedTitle === "Chat" && <Chat contrat={data} />}
      {selectedTitle === "Notes" && <Notes contrat={data} />}
    </Offcanvas>
  );
};

export default UpdateContracts;
